export const interestOptions = [
  {
    label: 'Spouwmuur<wbr>isolatie',
    value: 'Cavity Wall',
    icon: 'bi-house-wall',
    searchKeys: ['spouw', 'cavity'],
    viewName: 'formCavityWall',
    hidden: false
  },
  {
    label: 'Dakisolatie',
    value: 'Roof',
    icon: 'bi-house-roof',
    searchKeys: ['dak', 'roof'],
    viewName: 'formRoof',
    hidden: false
  },
  {
    label: 'Vloerisolatie',
    value: 'Floor',
    icon: 'bi-house-floor',
    searchKeys: ['floor', 'vloer', 'bodem'],
    viewName: 'formFloor',
    hidden: false
  },
  {
    label: 'H++ glas',
    value: 'Glazing',
    icon: 'bi-house-window',
    searchKeys: ['glas', 'glaz'],
    viewName: 'formGlazing',
    hidden: false
  },
  {
    label: 'Zonnepanelen',
    value: 'Solar',
    icon: 'bi-solar-roof',
    searchKeys: ['zonne', 'solar', 'panelen', 'pv'],
    viewName: 'formSolar',
    hidden: true
  },
  {
    label: 'Warmtepomp',
    value: 'Heatpump',
    icon: 'bi-heatpump',
    searchKeys: ['warmtepomp', 'airco', 'heatpump'],
    viewName: 'formHeatpump',
    hidden: true
  },
]

export function getInterestDefinition(interestValue = null, loose = false) {
  // if loose normalize first
  if (loose) {
    interestValue = normalizeInterestValue(interestValue);
  }
  // find and return interest definition object
  for (const interest of interestOptions) {
    if (interest.value == interestValue) {
      return interest;
    }
  }
  // else return null
  return null;
}

export function normalizeInterestValue(searchString = null) {
  if (!searchString) {
    return null;
  }
  // search interests by search keys
  let searchString_lc = String(searchString).toLowerCase();
  for (const interest of interestOptions) {
    // exact match
    if (interest.value == searchString) {
      return interest.value;
    }
    // loose match
    for (const sk of interest.searchKeys) {
      if (searchString_lc.includes(sk)) {
        return interest.value;
      }
    }
  }
  // else return null
  return null;
}

export function getInterestDefinitions($showHidden = false)
{
  if ($showHidden) {
    return interestOptions;
  } else {
    // return interests where hidden = false
    return interestOptions.filter(interest => interest.hidden == false);
  }
}