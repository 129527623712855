import { isString, omit, endsWith } from "lodash";

export const StripQuery = function(sourceQuery = null, paramNames = null, overrideGlobalRules = false) {
  let newQuery = sourceQuery;
  if (newQuery) {

    if (isString(paramNames)) {
      paramNames = [paramNames];
    } else if (paramNames == null) {
      paramNames = [];
    }
    if (!overrideGlobalRules) {
      paramNames.push('redir');
      paramNames.push('coupon');
    }

    // remove all keys ending in _once
    for (const key in newQuery) {
      if (endsWith(key, '_once')) {
        paramNames.push(key);
      }
    }

    newQuery = omit(newQuery, paramNames);
    
  }
  return newQuery;
}