<template>
  <div :class="btnGroupClass" role="group" aria-label="Radio toggle button group">
   <template v-for="option, key in options" :key="option.id">
     <base-button
      :variant="getVariant(option)"
      :class="{'border-0': true, 'option-active': optionIsActive(option)}"
      :disabled="disabled"
      @click="onOptionClick(option.value)"
      :icon="option.icon"
      icon-position="start"
      :aria-label="option.ariaLabel"
    >
      <span v-html="option.label"></span>
    </base-button>
    <div class="option-separator" v-if="key < options.length-1" v-show="showSeparator"></div>
   </template>
  </div>
</template>

<script>
  import BaseButton from "@/components/BaseButton.vue"

  export default {
    components: {
      BaseButton
    },
    props: {
      value: null,
      options: {
        type: [Object, Array],
        required: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      // passed to BaseButton
      variant: {
        type: String,
        default: 'info'
      },
      // applies background color to btn-group
      filled: {
        type: Boolean,
        default: true
      },
      // flex-direction
      direction: {
        type: String,
        default: 'row'
      },
      textAlign: {
        type: String,
        default: 'center'
      }
    },
    computed: {
      btnGroupClass() {
        return {
          'radio-buttons-input': true,
          'btn-group': true,
          'btn-group-filled': this.filled,
          ['variant-'+this.variant]: true,
          ['flex-'+this.direction]: true
        }
      },
      variantCssVar() {
        return 'var(--bs-' + this.variant + ')';
      }
    },
    methods: {
      onOptionClick(value) {
        this.$emit('update:value', value);
      },
      getVariant(option) {
        let activeVariant = this.variant;
        let inactiveVariant = 'outline-' + this.variant;
        return (this.optionIsActive(option)) ?
          activeVariant : inactiveVariant;
      },
      showSeparator(key) {
        return !(this.adjacentOptionIsActive(key) || this.optionIsActive(this.options[key]));
      },
      optionIsActive(option) {
        return (this.value !== null && option.value == this.value) ?
          true : false;
      },
      adjacentOptionIsActive(key) {
        if (key > 0 && this.optionIsActive(this.options[key-1])) {
          return true;
        }
        if (
          this.options.length > key + 1 &&
          this.optionIsActive(this.options[key+1])
        ) {
          return true;
        }
        return false;
      },
    }
  }
</script>

<style scoped lang="scss">
  .btn-group {
    padding: 3px;
    border-radius: $border-radius !important;
    max-width: 100%;
    flex-wrap: wrap;

    &.flex-column {
      width: 100%;
    }

    &.btn-group-filled {
      background: rgba($white, 0.4);
    }

    :deep(.btn) {
      line-height: 1.2em;
      &.option-active {
        opacity: 0.8;
      }
      text-align: v-bind(textAlign);
    }

    &.flex-column {
      :deep(.btn) {
        width: 100%;
        border-radius: $border-radius !important;
      }
    }
  }

  .option-separator {
    border-right: 1px solid v-bind(variantCssVar);
    opacity: 0.2;
  }
</style>