<template>
  <div :class="outerClasses">
    <div :class="innerClasses">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import ClassList from '@/helpers/ClassList'

  export default {
    props: {
      outerClass: { default: null },
      innerClass: { default: null },
      // can be any color name from the Bootstrap theme colors
      color: { default: null },
      opaque: { default: false },
      // controls whether the outer div will have padding
      outerPadding: { type: Boolean, default: false },
      // controls whether the inner-card will stretch to fit outer card
      stretchInner: { type: Boolean, default: false },
      // right angles
      dockTop: { type: Boolean, default: false },
      dockRight: { type: Boolean, default: false },
      dockBottom: { type: Boolean, default: false },
      dockLeft: { type: Boolean, default: false },
      // speech bubble
      bubbleTopLeft: { type: Boolean, default: false },
      bubbleTopRight: { type: Boolean, default: false },
      bubbleBottomRight: { type: Boolean, default: false },
      bubbleBottomLeft: { type: Boolean, default: false },
      // reduced border radius
      reducedRadius: { type: Boolean, default: false },
      nearSquare: { type: Boolean, default: false },
    },
    computed: {
      outerClasses() {
        return ClassList({ 'card-outer': true, 'outer-padding': this.outerPadding, 'stretch-inner': this.stretchInner }).addClass(this.outerClass).getAll()
      },
      innerClasses() {
        let cl = ClassList({
            'card-inner': true,
            'dock-top': this.dockTop,
            'dock-right': this.dockRight,
            'dock-bottom': this.dockBottom,
            'dock-left': this.dockLeft,
            'bubble-top-left': this.bubbleTopLeft,
            'bubble-top-right': this.bubbleTopRight,
            'bubble-bottom-right': this.bubbleBottomRight,
            'bubble-bottom-left': this.bubbleBottomLeft,
            'reduced-radius': this.reducedRadius,
            'near-square': this.nearSquare,
          })
          .addClass(this.innerClass)
          .getAll()
        // if color prop is set, add class
        if (this.color) {
          this.opaque
            ? cl['card-'+this.color+'-opaque'] = true
            : cl['card-'+this.color] = true
        }
        return cl
      }
    }
  }
</script>

<style scoped lang="scss">

  .card-outer {
    margin: 0 0;
    display: flex;
    align-items: flex-start;
    &.stretch-inner {
      align-items: stretch;
      .card-inner {
        flex: 1;
      }
    }
    &.outer-padding {
      padding: map-get($spacers, 3);
    }

    .card-inner {
      padding: map-get($spacers, 4);
      border-radius: $border-radius * 2;
      @include std-box-shadow(3, 2);

      &.reduced-radius {
        border-radius: $border-radius;
      }
      &.near-square {
        border-radius: 3px;
      }
      
      // BACKGROUND COLORS / VARIANTS
      // default
      background-color: rgba($gray-100, 0.8);
      // grayscale
      @each $color, $value in $grayscale {
        &.card-gray-#{$color} {
          background-color: rgba($value, 0.8);
        }
        &.card-gray-#{$color}-opaque {
          background-color: rgba($value, 1);
        }
      }
      // theme colors
      @each $color, $value in $theme-colors {
        &.card-#{$color} {
          background-color: rgba($value, 0.2);
        }
        &.card-#{$color}-opaque {
          background-color: rgba($value, 1);
        }
      }
      // standardized alternate
      &.card-alternate {
        background-color: rgba($gray-400, 0.8);
      }
      &.card-danger {
        background-color: rgba(saturate(lighten($danger, 10%), 10%), 0.2);
      }
      // shadow adjustments for very light colors
      &.card-light,
      &.card-gray-0, 
      &.card-gray-100, 
      &.card-gray-200 {
        @include std-box-shadow(3, 1);
      }
      // hard white variant, no transparency
      &.card-white {
        background-color: $white;
        @include std-box-shadow(3, 1);
      }

      // REMOVE ROUNDED CORNERS ON ONE SIDE
      &.dock-top {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      &.dock-right {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &.dock-bottom {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      &.dock-left {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      // REMOVE ROUNDED CORNER ON ONE CORNER
      &.bubble-top-left {
        border-top-left-radius: 0;
      }
      &.bubble-top-right {
        border-top-right-radius: 0;
      }
      &.bubble-bottom-right {
        border-bottom-right-radius: 0;
      }
      &.bubble-bottom-left {
        border-bottom-left-radius: 0;
      }
    }
  }
</style>