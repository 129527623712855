export default {
  meta: {
    sfuuid: '0000000000000000000AAA',
    PartnerCoupon: '2K7SA2',
    AllInterestsEnabled: true
  },
  interests: {
    Interests: ['Cavity Wall', 'Roof', 'Floor', 'Glazing'],
    UrgencyRating: 'Zo snel mogelijk',
    Motivation: 'Energie besparen',
  },
  contact: {
    FirstName: 'John',
    LastName: 'Doe',
    Email: 'test@bureauverduurzamen.nl',
    Phone: '0503600322',
  },
  address: {
    Street: 'Atoomweg',
    StreetNumber: '10',
    PostalCode: '9743AK',
    City: 'Groningen',
  },
  ownership: {
    PropertyOwner: true,
    HoaMember: false,
  },
  property: {
    ConstructionYear: 1970,
    GasConsumption: 2000,
    PowerConsumption: 2500,
    HouseType: 'Hoekwoning',
  },
  cavity_wall: {
    general: {
      CavityWallPresent: true,
      FacadeMaterial: 'Hout',
    },
    paint: {
      WallsPainted: true,
      AcceptWallRepaint: true,
    },
    stucco: {
      WallsPlastered: true,
      AcceptStuccoRepaint: true,
    },
  },
  floor: {
    general: {
      SpaceBeneathFloor: true,
      CrawlSpaceAccessible: true,
    },
    details: {
      CrawlSpaceHeightCm: 25,
      FloorInsulationSide: 'Vloer',
    },
  },
  roof: {
    general: {
      RoofInsulationStatus: 'Volledig geisoleerd',
      RoofInsulationSide: 'outside',
      // RoofReplacementWithinDecade: null,
    },
    attic: {
      AtticPresent: true,
      AccessToRoofInside: 'Trap',
      AtticUsage: ['living'],
    },
    finishing: {
      RoofBeamsVisible: true,
      RoofInsideFinishingPresent: true,
      RoofBeamCoveringRemoved: true,
    },
  },
  glazing: {
    general: {
      IsMonument: true,
      BuildingAccessibleAllround: true,
      CurrentGlazingType: 'dubbel',
      CurrentGlazingAge: 'voor-1995',
    },
    frame: {
      FrameMaterial: 'hout',
      FrameCondition: 'good',
      RebateWidthSufficient: true,
    },
  },
};