<template>
  <div class="navbar-wrapper">

    <div class="fade-left" v-show="!fromResultMode"></div>
    <div class="arrow-left" v-show="showArrowLeft && !hideArrows && !fromResultMode">
      <i class="bi bi-chevron-left" @click="scroll('left')"></i>
    </div>

    <div class="navbar" ref="navbar" v-show="!fromResultMode">
      <div class="navbar-ticker" ref="ticker">
        <template v-for="item in navItems" :key="item">

          <div class="nav-divider" v-if="item.dividerBefore">
            <figure></figure>
          </div>

          <router-link :to="{ name: item.name, query: $route.query, params: $route.params }" custom v-slot="{ href, navigate, isActive }">
            <base-button :href="href" @click="onNavigate(navigate, $event, item)" tag="a" :icon="item.icon" iconPosition="start" :class="getItemClass(item, isActive)" :variant="getItemVariant(item, isActive)">{{item.label}}</base-button>
          </router-link>

          <div class="nav-divider" v-if="item.dividerAfter">
            <figure></figure>
          </div>

        </template>
      </div>
    </div>

    <div class="navbar justify-content-end" ref="resultBar" v-show="fromResultMode">
      <div class="result-tools">
        <router-link :to="{ name: resultItem.name, query: removeQueryField($route.query, 'from'), params: $route.params }" custom v-slot="{ href, navigate }">
          <base-button
            :href="href"
            @click="onNavigate(navigate, $event, resultItem)"
            tag="a"
            :icon="resultItem.icon"
            iconPosition="start"
            :class="getItemClass(resultItem, true)"
            :variant="getItemVariant(resultItem, true)">
            {{resultItem.label}}
            <i class="bi bi-arrow-right"></i>
          </base-button>
        </router-link>
      </div>
    </div>

    <div class="fade-right" v-show="!fromResultMode"></div>
    <div class="arrow-right" @click="scroll('right')" v-show="showArrowRight && !hideArrows && !fromResultMode">
      <i class="bi bi-chevron-right"></i>
    </div>

  </div>
</template>

<script>
import { isFunction } from 'lodash';
import { FlowService } from '@/services/FlowService';
import BaseButton from '@/components/BaseButton.vue';

export default {
  components: {
    BaseButton
  },
  props: {
    variant: {
      type: String,
      default: 'minimal-primary'
    },
    variantActive: {
      type: String,
      default: 'primary'
    },
    variantChild: {
      type: String,
      default: 'minimal-filled-primary'
    },
    touchAndValidate: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      navbarEl: null,
      tickerEl: null,
      showArrowLeft: false,
      showArrowRight: true,
      hideArrows: false,
      resultItem: {
        name: 'qualificationResult',
        label: 'Voltooien',
        icon: 'bi-trophy-fill'
      }
    }
  },
  computed: {
    navItems() {
      return FlowService.getNavItems(this.$route.name);
    },
    firstActiveItem() {
      let ticker = this.$refs.ticker;
      let activeItem = ticker.querySelector('.active-link');
      return activeItem ? activeItem : null;
    },
    fromResultMode() {
      return (this.$route && this.$route.query.from_result == 'true');
    }
  },
  methods: {
    getItemVariant(item, isActive) {
      if (isActive) {
        return this.variantActive;
      } else if (item.activeChild) {
        return this.variantChild;
      } else {
        return this.variant;
      }
    },
    getItemClass(item, isActive) {
      return {['active-child']: item.activeChild, 'active-link': isActive };
    },
    onNavigate(navigate, event, item) {
      event.preventDefault();
      if (!isFunction(this.touchAndValidate)) {
        navigate();
      } else {
        if (item.beforeCurrent) {
          navigate();
        } else if (this.touchAndValidate()) {
          navigate();
        }
      }
      return false;
    },

    setScrollLeft(value) {
      this.setElementsOnce();
      this.navbarEl.scrollLeft = value;
      this.afterScroll(value);
    },
    afterScroll(scrollPos = null) {
      if (!scrollPos) {
        scrollPos = this.navbarEl.scrollLeft;
      }
      // show / hide left arrow
      if (scrollPos < 1) {
        this.showArrowLeft = false;
      } else {
        this.showArrowLeft = true;
      }
      // show / hide right arrow
      if ((this.navbarEl.scrollWidth - this.navbarEl.clientWidth - scrollPos) < 1) {
        this.showArrowRight = false;
      } else {
        this.showArrowRight = true;
      }
    },
    scroll(direction) {
      if (direction == 'left') {
        this.setScrollLeft(this.navbarEl.scrollLeft - 400);
      } else if (direction == 'right') {
        this.setScrollLeft(this.navbarEl.scrollLeft + 400);
      }
    },
    scrollTicker() {
      let ticker = this.$refs.ticker;
      let navbar = this.$refs.navbar;
      let activeItem = this.firstActiveItem;
      let lastItem = ticker.querySelector('.btn:last-of-type');
      if (activeItem) {
        let scrollLeft = activeItem.offsetLeft;
        // ticker.style.transform = 'translateX(-'+translateX+'px)';
        ticker.style.marginLeft = '-'+(activeItem.offsetWidth / 2)+'px';
        lastItem.style.marginRight = '-'+(lastItem.offsetWidth / 2)+'px';
        this.setScrollLeft(scrollLeft);
      }
      navbar.style.scrollBehavior = 'smooth';
    },
    
    setElementsOnce() {
      if (!this.navbarEl) {
        this.navbarEl = this.$refs.navbar;
      }
      if (!this.tickerEl) {
        this.tickerEl = this.$refs.ticker;
      }
    },
    subscribeTickerScroll() {
      this.setElementsOnce();
      this.navbarEl.addEventListener("wheel", this.onTickerScroll);
      this.tickerEl.addEventListener("wheel", this.onTickerScroll);
    },
    unSubscribeTickerScroll() {
      this.navbarEl.removeEventListener("wheel", this.onTickerScroll);
      this.tickerEl.removeEventListener("wheel", this.onTickerScroll);
    },
    onTickerScroll(event) {
      event.preventDefault();
      event.stopPropagation();
      this.setScrollLeft(this.navbarEl.scrollLeft + (event.deltaY * 4));
    },

    removeQueryField(query = null, field = null) {
      if (query && field && field in query) {
        delete query.from_result;
      }
      return query;
    }
  },
  mounted() {
    // hide arrows on touch screen
    if (window.matchMedia("(pointer: coarse)").matches) {
      this.hideArrows = true;
    }
    this.$nextTick(() => {
      this.subscribeTickerScroll();
      this.scrollTicker();
    });
  },
  unmounted() {
    this.unSubscribeTickerScroll();
  }
}
</script>

<style scoped lang="scss">


  $nav-item-margin-right: 20px;

  .navbar {
    position: relative;
    padding: map-get($spacers, 4);
    overflow-x: scroll;
    width: 100%;
    display: flex;
     /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    .navbar-ticker {
      position: relative;
      left: 50%;
      padding-right: 50vw;
      min-width: max-content;
      display: flex;
      align-items: center;

      .btn {
        margin-right: $nav-item-margin-right;
      }
      .btn:not(.active-link):not(.active-child) {
        opacity: 0.6;
      }
    }
  }

  .navbar-wrapper {
    position: relative;
  }

  .fade-right, .fade-left {
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 150px;
    max-width: 20%;
    z-index: 10;

    @include media-breakpoint-down(sm) {
      width: 150px;
      max-width: 10%;
    }
  }
  .fade-right {
    right: 0;
    background: linear-gradient(90deg, rgba($std-background, 0) 20%, rgba($std-background, 1) 100%);
  }
  .fade-left {
    left: 0;
    background: linear-gradient(-90deg, rgba($std-background, 0) 20%, rgba($std-background, 1) 100%);
  }

  .arrow-left, .arrow-right {
    position: absolute;
    z-index: 99;
    top: 0;
    bottom: 0;    
    font-size: 1.7em;
    display: flex;
    align-items: center;
    pointer-events: none;
    color: $primary;

    i {
      padding: 20px;
      pointer-events: all;
      cursor: pointer;
    }

    @include media-breakpoint-down(sm) {
      i {
        padding: 20px 5px;
      }
    }
  }

  .arrow-left {
    left: 0;
  }
  .arrow-right {
    right: 0;
  }

  .nav-divider {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: $nav-item-margin-right + 1px;

    figure {
      display: block;
      width: 30px;
      background-color: $gray-300;
      height: 2px;
      margin: auto;
    }
  }

</style>