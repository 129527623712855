<template>
  <toast-container/>
  <router-view/>
</template>

<script> // REMOVE BEFORE FLIGHT
  import { useQualificationStore } from '@/store/QualificationStore.js';
  import { onBeforeUnload } from '@/services/BeforeUnloadService.js';
  import ToastContainer from '@/components/ToastContainer.vue';

  export default {
    components: {
      ToastContainer
    },
    setup() {
      const qualStore = useQualificationStore();
      return { qualStore };
    },
    mounted() {
      window.addEventListener("beforeunload", onBeforeUnload);
    },
    beforeUnmount() {
      window.removeEventListener("beforeunload", onBeforeUnload);
    }
  }
</script>

<style lang="scss">
  * {
    animation-duration: 0.4s;
    animation-fill-mode: both;
  }
</style>