<template>
  <transition name="fade-in-down">
    <div class="toast-animation-wrapper" v-show="showToast">
      <div ref="el" :class="toastClasses" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong
            class="me-auto"
            v-html="title"></strong>
          <small
            v-if="subtitle"
            class="text-muted"
            v-html="subtitle"></small>
          <button
            v-if="closeButton"
            @click="close"
            type="button"
            class="btn-close"
            aria-label="Close"></button>
        </div>
        <div v-if="body" class="toast-body" v-html="body"></div>
      </div>
    </div>
  </transition>
</template>

<script>
import ClassList from '@/helpers/ClassList';

export default {
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    body: {
      type: String
    },
    closeButton: {
      type: Boolean,
      default: true
    },
    animation: {
      type: Boolean,
      default: true
    },
    autohide: {
      type: Boolean,
      default: true
    },
    delay: {
      type: Number,
      default: 5000
    },
    variant: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      showToast: false
    }
  },
  computed: {
    toastClasses() {
      let cl = new ClassList('toast');
      if (this.variant) {
        cl.addClass('toast-'+this.variant)
      }
      return cl.getAll();
    }
  },
  methods: {
    close() {
      this.showToast = false;
    }
  },
  mounted() {
    // show after small delay
    window.setTimeout(() => {
      this.showToast = true;
      // auto hide if enabled
      if (this.autohide) {
        window.setTimeout(() => {
          this.showToast = false;
        }, this.delay);
      }
    }, 500);
  },
}
</script>

<style scoped lang="scss">
  .toast-animation-wrapper {
    animation-duration: 1.5s;
  }

  .toast {
    display: block !important;
    margin: 10px;
    .toast-header {
      strong {
        font-weight: 500;
      }
      .text-muted {
        opacity: 0.7;
      }
    }

    // theme colors
    @each $color, $value in $theme-colors {
      &.toast-#{$color} {
        background-color: $value;
        color: $white;

        * {
          color: $white;
        }

        .text-muted {
          color: $white !important;
        }

        .toast-header {
          background: transparent;
        }

        .toast-body {
          background-color: rgba($white, 0.1);
        }
      }
    }
  }
</style>