import { useAddressStore } from '@/store/AddressStore';
import { useInterfaceStore } from '@/store/UiStore';
import axios from 'axios';
import constants from '@/constants';
// import { touchAllWithValue } from '@/store/LeadValidator';

export var api = axios.create({
  baseURL: constants.baseURL,
});

export const AddressDataService = {

  fetchAddress(postal_code = null, street_number = null) {
    console.log('fetchAddress', postal_code, street_number);
    let queryString = '?';
    queryString += 'postal_code=' + postal_code;
    queryString += '&street_number=' + street_number;
    return new Promise((resolve, reject) => {
      const addressStore = useAddressStore();
      const uiStore = useInterfaceStore();
      uiStore.addressFetchBusy = true;
      // new api call
      api
        .get('/rest/address' + queryString)
        .then(function (response) {
          if (response.data && response.data.data && response.data.status == 'success') {
            // address found and valid
            addressStore.loadAddressData(response.data.data);
            resolve();
          } else {
            addressStore.loadAddressData(null);
            reject(false);
          }
        })
        .catch(function (error) {
          // connection error
          console.error(error);
          reject();
        })
        .finally(() => {
          uiStore.addressFetchBusy = false;
        });
    });
  },

};
