import { helpers } from '@vuelidate/validators';
import { isEmpty, isString } from 'lodash';

/**
 * require field if another field has given value
 * @param {string} masterName - name of the sibling field upon which the requirement for this field depends
 * @param {*} expectedValue - the value which the parent field must match in order to make the dependent field required
 * @param {boolean} isTruthy - when true the master field's value must be identical to the expectedValue, if false it must NOT be equal
 * @param {boolean} acceptBooleanString - when true, the string 'true' and 'false' will be converted to booleans
 * @returns boolean
 */
export const requireIfField = (masterName, expectedValue = true, isTruthy = true, acceptBooleanString = true) => (value, form) => {
  if (formFieldEquals(form, masterName, expectedValue, isTruthy, acceptBooleanString)) {
    return helpers.req(value);
  } else {
    return true; // always valid if not required
  }
}

/**
 * require field if another field has given value
 * @param {Map<String, *>} fieldValuePairs - name of the sibling field with the required value
 * @param {string} logicalOperator - can be 'AND' or 'OR'
 * @param {boolean} isTruthy - when true the master field's value must be identical to the expectedValue, if false it must NOT be equal
 * @param {boolean} acceptBooleanString - when true, the string 'true' and 'false' will be converted to booleans
 * @returns boolean
 */
export const requireIfFields = (fieldValuePairs, logicalOperator = 'AND', isTruthy = true, acceptBooleanString = true) => (value, form) => {
  let countTrue = 0;
  let allTrue = false;
  let anyTrue = false;
  // check if fieldValuePairs actually has items
  if (isEmpty(fieldValuePairs)) { return true; } // always valid if fieldValuePairs is empty
  // iterate through field value pairs
  for (const [fieldName, expectedValue] of Object.entries(fieldValuePairs)) {
    // field value is expected value
    if (formFieldEquals(form, fieldName, expectedValue, isTruthy, acceptBooleanString)) {
      countTrue++;
      anyTrue = true;
    }
  }
  // check if all are true
  if (countTrue == Object.keys(fieldValuePairs).length) {
    allTrue = true;
  }
  if ((logicalOperator == 'AND' && allTrue) || (logicalOperator == 'OR' && anyTrue)) {
    return helpers.req(value);
  } else {
    return true; // always valid if not required
  }
}

/**
 * check if form field equals specific value
 * @param {object} form - form object, received from vuelidate
 * @param {string} masterName - name of the sibling field upon which the requirement for this field depends
 * @param {*} expectedValue - the value which the parent field must match in order to make the dependent field required
 * @param {boolean} isTruthy - when true the master field's value must be identical to the expectedValue, if false it must NOT be equal
 * @param {boolean} acceptBooleanString - when true, the string 'true' and 'false' will be converted to booleans
 * @returns boolean
 */
export const formFieldEquals = (form, masterName, expectedValue = true, isTruthy = true, acceptBooleanString = true) => {
  if (masterName in form) {
    let masterValue = form[masterName];
    // if acceptBooleanString, convert true/false strings to boolean
    if (
      acceptBooleanString &&
      !isString(expectedValue) &&
      isString(masterValue)
    ) {
      if (masterValue.toLowerCase() == 'true') {
        masterValue = true;
      } else if (masterValue.toLowerCase() == 'false') {
        masterValue = false;
      }
    }
    return isTruthy
      ? masterValue == expectedValue
      : masterValue !== expectedValue;
  }
  return false;
};
