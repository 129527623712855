import { computed } from "vue";
import { QualIssue } from '@/services/QualClasses';
import { useFormStores as useStores } from '@/rules/_useFormStores';

export const rules = {
  // check if enabled and complete
  // enabledAndIncomplete: computed(() => {
  //   let { lead, validator } = useStores('cavity_wall', true);
  //   // check if interest is checked
  //   if (!lead.interestsArray.includes('Cavity Wall')) {
  //     return null;
  //   }
  //   // if checked, check if form is valid
  //   if (validator.$invalid) {
  //     return new QualIssue({
  //       view: 'formCavityWall',
  //       title: 'Belangrijke informatie ontbreekt',
  //       description:
  //         'Niet alle vereiste informatie is ingevuld. Daarom kunnen wij (nog) niet garanderen dat u in aanmerking komt voor spouwmuurisolatie.',
  //     });
  //   }
  //   return null;
  // }),

  // spouwmuur aanwezig
  // CavityWallPresent: computed(() => {
  //   let { form } = useStores('cavity_wall');
  //   if (form.general.CavityWallPresent == false) {
  //     return new QualIssue({
  //       view: 'formCavityWall',
  //       title: 'Geen spouwmuur aanwezig',
  //       description:
  //         'Indien er geen spouwmuur aanwezig is, kunnen wij geen spouwmuurisolatie uitvoeren.',
  //     });
  //   }
  //   return null;
  // }),

  // materiaal buitenmuur
  // FacadeMaterial: computed(() => {
  //   let { form } = useStores('cavity_wall');
  //   if (form.general.FacadeMaterial && form.general.FacadeMaterial !== 'Steen_spouw') {
  //     return new QualIssue({
  //       view: 'formCavityWall',
  //       title: 'Buitenmuur is ongeschikt',
  //       description: `Bij de aangegeven soort buitenmuur (${form.general.FacadeMaterial}) kunnen wij geen spouwmuurisolatie uitvoeren.`,
  //     });
  //   }
  //   return null;
  // }),

  // geverfde muren
  // WallsPainted: computed(() => {
  //   let { form } = useStores('cavity_wall');
  //   if (form.paint.WallsPainted) {
  //     return new QualIssue({
  //       view: 'formCavityWallPaint',
  //       type: 'warning',
  //       title: 'Let op: risico op schade!',
  //       description:
  //         'Bij geverfde muren bestaat er een risico op vorstschade omdat er mogelijk vocht in de muur komt. Wij raden u af te isoleren.',
  //     });
  //   }
  //   return null;
  // }),

  // muur opnieuw verven
  VapourPermeablePaint: computed(() => {
    let { form } = useStores('cavity_wall');
    if (form.paint.WallsPainted && (form.paint.VapourPermeablePaint == 'Nee' || form.paint.VapourPermeablePaint == 'Weet ik niet')) {
      return new QualIssue({
        type: 'error',
        color: 'error',
        view: 'formCavityWallPaint',
        punishEarly: true,
        title: 'Negatief advies bij dampdichte verf',
        description:
          'Wanneer de gevel aan de buitenzijde is bedekt in een dampdichte verflaag, bestaat er grote kans op schade wanneer deze geïsoleerd wordt. Wij raden over het algemeen af dergelijke gevels te isoleren.',
      });
    }
    return null;
  }),

  // stucwerk
  // AcceptStuccoRepaint: computed(() => {
  //   let { form } = useStores('cavity_wall');
  //   if (form.stucco.WallsPlastered && form.stucco.AcceptStuccoRepaint == false) {
  //     return new QualIssue({
  //       view: 'formCavityWallStucco',
  //       title: 'Stucwerk opnieuw verven & reliëf aanbrengen',
  //       description:
  //         'Bij isolatie zouden de boorgaten moeten worden hersteld en het reliëf opnieuw moeten worden aangebracht. U geeft aan dit niet te willen. In dat geval kunnen wij niet isoleren.',
  //     });
  //   }
  //   return null;
  // }),

  JointSize: computed(() => {
    let { form } = useStores('cavity_wall');
    if (form.masonry.JointSize && form.masonry.JointSize == 'kleiner dan 14 mm') {
      return new QualIssue({
        type: 'warning',
        color: 'warning',
        view: 'formCavityWallMasonry',
        punishEarly: true,
        title: 'Risico op schade aan bakstenen',
        description:
          'De kleinste gecertificeerde boordiameter is 14 mm. Bij kleinere voegen bestaat er een risico op schade aan de bakstenen.',
      });
    }
    return null;
  }),

  Cracking: computed(() => {
    let { form } = useStores('cavity_wall');
    if (form.defects.Cracking && form.defects.Cracking == 'Grotere scheur') {
      return new QualIssue({
        type: 'warning',
        color: 'error',
        view: 'formCavityWallDefects',
        punishEarly: true,
        title: 'Herstel nodig vóór isolatie',
        description:
          'Bij grote scheuren in de gevel is het noodzakelijk de scheuren eerst te herstellen voordat er geïsoleerd wordt.',
      });
    }
    return null;
  }),

  Access: computed(() => {
    let { form } = useStores('cavity_wall');
    if (form.access.LadderAccess && (
      form.access.LadderAccess == 'Aanbouw met schuin dak' ||
      form.access.LadderAccess == 'Schuine overkapping halverwege gevel' ||
      form.access.LadderAccess == 'Aanbouw/overkapping is niet beloopbaar'
    )) {
      return new QualIssue({
        type: 'addon',
        color: 'secondary',
        view: 'formCavityWallAccess',
        icon: 'bi-check-circle-fill',
        punishEarly: true,
        title: 'Hoogwerker nodig',
        description:
          'Vanwege de bereikbaarheid van de gevels is een hoogwerker waarschijnlijk noodzakelijk.',
      });
    }
    return null;
  }),
  
};
