<template>
  <Transition name="fade-in-up">
    <div class="autosave-bar" v-show="uiStore.saving">
      <div class="autosave-message pulse-opacity-infinite-subtle">
        <i class="bi bi-arrow-down-up me-2"></i>
        Opslaan...
      </div>
    </div>
  </Transition>
  <Transition name="fade-in-up">
    <div class="autosave-bar" v-show="uiStore.savingError">
      <div class="autosave-message text-danger-dark">
        <i class="bi bi-x-lg me-2"></i>
        Fout bij opslaan
      </div>
    </div>
  </Transition>
</template>

<script>
import { useAllStores } from '@/helpers/UseAllStores';

export default {
  setup() {
    return useAllStores();
  }
}
</script>

<style scoped lang="scss">
.autosave-bar {
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 9999;
  pointer-events: none;

  .autosave-message {
    display: inline-block;
    color: $primary;
    font-size: 0.7em;
    padding: 5px 20px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}
</style>