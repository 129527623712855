// import { useCouponStore } from '@/store/CouponStore';
import { useLeadStore } from '@/store/LeadStore';
import axios from 'axios';
import constants from '@/constants';
// import { touchAllWithValue } from '@/store/LeadValidator';

export var api = axios.create({
  baseURL: constants.baseURL,
});

export const VerificationService = {

  sendVerification(uuid) {
    return new Promise((resolve, reject) => {
      let lead = useLeadStore();
      if (!uuid) {
        reject(false);
      }
      if (lead.meta.VerificationSent) {
        // if less than 30 seconds since last verification email sent
        if (new Date() - lead.meta.VerificationSent < 30000) {
          reject(false);
        }
      }
      // new api call
      api
        .post('/rpc/lead/verification_email/' + uuid)
        .then(function (response) {
          if (response.data && response.data.data && response.data.status == 'success') {
            // set current date time on Verfiication Email Sent
            lead.meta.VerificationSent = new Date();
          } else {
            reject(false);
          }
        })
        .catch(function (error) {
          // connection error
          console.error(error);
          reject(false);
        });
    });
  },

};
