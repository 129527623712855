import { isObject, merge } from "lodash";
import { defineStore } from "pinia";
import { AddressDataService } from "@/services/AddressDataService";
import { useLeadStore } from "@/store/LeadStore";

export const useAddressStore = defineStore({
  id: 'addressStore',
  state: () => {
    return {
      id: null,
      eligible: null,
      Street: null,
      StreetNumber: null,
      Street_number_addition__c: null,
      Street_number_letter__c: null,
      PostalCode: null,
      City: null,
      BAG_Object_ID__c: null,
      BAG_nummeraanduidingIdentificatie__c: null,
      Energielabel_datum__c: null,
      Energielabel__c: null,
      BAG_openbareRuimteNaam__c: null,
      BAG_korteNaam__c: null,
      BAG_woonplaatsNaam__c: null,
      BAG_huisnummer__c: null,
      BAG_postcode__c: null,
      BAG_openbareRuimteIdentificatie__c: null,
      BAG_woonplaatsIdentificatie__c: null,
      BAG_adresregel5__c: null,
      BAG_adresregel6__c: null,
      BAG_typeAdresseerbaarObject__c: null,
      BAG_oppervlakte__c: null,
      BAG_gebruiksdoelen__c: null,
      BAG_oorspronkelijkBouwjaar__c: null,
      BAG_pandStatussen__c: null,
      BAG_self_link__c: null,
      BAG_openbareRuimte_link__c: null,
      BAG_nummeraanduiding_link__c: null,
      BAG_woonplaats_link__c: null,
      BAG_adresseerbaarObject_link__c: null,
      BuildingCluster__c: null,
      EP_postcode__c: null,
      EP_afschrift__c: null,
      EP_gebouwtype__c: null,
      EP_huisletter__c: null,
      EP_huisnummer__c: null,
      EP_gebouwklasse__c: null,
      EP_berekeningstype__c: null,
      EP_metingGeldigTot__c: null,
      EP_detailaanduiding__c: null,
      EP_bagVerblijfsobjectId__c: null,
      EP_huisnummertoevoeging__c: null,
      EP_isVereenvoudigdLabel__c: null,
      EP_isOpBasisVanReferentiegebouw__c: null,
      created_at: null,
      last_updated: null
    }
  },
  actions: {
    fetchAddress(postalCode = null, streetNumber = null) {
      AddressDataService.fetchAddress(postalCode, streetNumber)
        .then(() => {
          console.log('Address found for :' + postalCode + ' ' + streetNumber);
          this.updateLeadStore();
        })
        .catch(() => {
          console.log('Address not found for :' + postalCode + ' ' + streetNumber);
        });
    },
    updateLeadStore() {
      const lead = useLeadStore();
      // get all keys from $state
      for (let key in this.$state) {
        if (key === 'PostalCode' || key === 'StreetNumber') {
          continue;
        }
        lead.address[key] = this.$state[key];
      }
    },
    loadAddressData(addressData = null) {
      this.clearAddressData();
      if (addressData && isObject(addressData)) {
        merge(this.$state, addressData);
      }
    },
    clearAddressData(clearLeadAddress = false) {
      this.$reset();
      if (clearLeadAddress) {
        this.updateLeadStore();
      }
    },
    diffLeadAddress(caseSensitive = false) {
      const lead = useLeadStore();
      let postal_code = this.$state.PostalCode || '';
      let street_number = this.$state.StreetNumber || '';
      let lead_postal_code = lead.address.PostalCode || '';
      let lead_street_number = lead.address.StreetNumber || '';
      if (!caseSensitive) {
        postal_code = postal_code.toLowerCase();
        street_number = street_number.toLowerCase();
        lead_postal_code = lead_postal_code.toLowerCase();
        lead_street_number = lead_street_number.toLowerCase();
      }
      return postal_code !== lead_postal_code || street_number !== lead_street_number;
    }
  }
});