<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div style="max-width: 438px;">
      <!-- title above form card -->
      <h1 class="px-4 text-primary fade-in-up-enter-active animation-slowest">Unieke link verstuurd</h1>
      <h4 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200">Open de link in de e-mail om verder te gaan</h4>
      <!-- form card -->
      <base-card inner-class="w-100" color="alternate" :outer-padding="true">
        <checkmark-item class="mb-2 small fw-500" icon="check-circle-fill">
          Er is een unieke link naar uw e-mailadres verstuurd.
        </checkmark-item>
        <checkmark-item class="mb-2 fw-500 text-primary mt-4" icon="exclamation-circle-fill">
          Volg de link in de e-mail om te starten.
        </checkmark-item>
      <!-- form card end -->
      </base-card>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  // import BaseForm from "@/components/BaseForm.vue"
  // import BaseInput from "@/components/BaseInput.vue"
  // import BaseButton from "@/components/BaseButton.vue"
  // import ButtonRow from "@/components/ButtonRow.vue"
  import CheckmarkItem from "@/components/CheckmarkItem.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"
  import {VerificationService} from "@/services/VerificationService"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      // BaseForm,
      // BaseInput,
      // BaseButton,
      // ButtonRow,
      CheckmarkItem
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    computed: {
      greetingQuestion() {
        return 'Vul uw e-mailadres in en ontvang de werkbon'
        // if (this.uiStore.isExistingCustomer) {
        //   return 'Kloppen deze gegevens?';
        // } else {
        //   return 'Hoe kunnen we u bereiken?';
        // }
      },
      greeting() {
        return 'Start de demo'
        // if (this.uiStore.isExistingCustomer) {
        //   if (this.lead.contact.FirstName) {
        //     return 'Hallo '+this.lead.contact.FirstName+'!';
        //   } else {
        //     return 'Hallo!';
        //   }
        // } else {
        //   return 'Hallo!'
        // }
      }
    },
    methods: {
      touchAndValidate() {
        this.v$.contact.$touch();
        return !this.v$.contact.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
    },
    mounted() {
      // if lead.meta.Verified = true
      if (this.lead.meta.Verified) {
        // redirect to formCavityWallPaint
        this.$router.push({ name: 'formCavityWallPaint' });
      } else {
        VerificationService.sendVerification(this.$route.params.uuid)
          .then(() => {
            console.log('Verification email sent');
          })
          .catch((error) => {
            console.error('Error sending verification email', error);
          });
      }
    }
  }
</script>

<style lang="scss">
</style>