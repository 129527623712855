import { computed } from 'vue';
import { useGlobalVuelidate } from '@/store/LeadValidator';
import { measures } from '@/helpers/Measures';

export class QualSection {
  key = null; // key of section/form in LeadStore
  rules = {}; // reactive object containing all rules for qualification

  constructor(options = {}) {
    for (const key in options) {
      this[key] = options[key];
    }
  }

  measure = computed(() => {
    return measures[this.key];
  });

  complete = computed(() => {
    let validator = this.getValidator();
    if (validator) {
      return validator.$invalid == false;
    } else {
      return true;
    }
  });

  qualified = computed(() => {
    // if incomplete
    if (!this.complete) {
      return false;
    }
    // if any rules with type == 'error' exist
    let rules = this.rules;
    for (const key in rules) {
      if (rules[key] && rules[key].type == 'error') {
        return false;
      }
    }
    return true;
  });

  hasWarning = computed(() => {
    // if any rules with type == 'warning' exist
    let rules = this.rules;
    for (const key in rules) {
      if (rules[key] && rules[key].type == 'warning') {
        return true;
      }
    }
    return false;
  });

  status = computed(() => {
    if (this.qualified.value) {
      return 'qualified';
    } else if (this.complete.value && this.qualified.value == false) {
      return 'disqualified';
    } else {
      return 'incomplete';
    }
  });

  issues = computed(() => {
    var issues = [];
    for (const key in this.rules) {
      let QualIssue = this.rules[key];
      if (QualIssue) {
        issues.push(QualIssue);
      }
    }
    return issues;
  });
  
  getValidator() {
    let v$ = useGlobalVuelidate();
    return v$.value[this.key];
  }
}

export class QualIssue {
  type = 'error'; // 'error' or 'warning'
  color = 'error';
  punishEarly = true; // show warning on form
  title = null; // short error title
  description = null; // more detailed description of the issue
  icon = 'bi-dash-circle';
  view = null; // when set, a link to the view containing the field causing the issue can be displayed

  constructor(options = {}) {
    if (!options.color) {
      options.color = options.type ? options.type : 'error';
    }
    for (const key in options) {
      this[key] = options[key];
    }
  }
}
