import { useLeadStore } from '@/store/LeadStore';
import { useGlobalVuelidate } from '@/store/LeadValidator';

export function useFormStores(formKey, useValidator = false) {
  let lead = useLeadStore();
  let form = lead[formKey];

  if (useValidator) {
    let v$ = useGlobalVuelidate();
    let validator = v$.value[formKey];
    return {
      lead: lead,
      form: form,
      v$: v$,
      validator: validator,
    };
  }

  return {
    lead: lead,
    form: form,
  };
}
