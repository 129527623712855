// import { useLeadStore } from "@/store/LeadStore";
import { useInterfaceStore } from "@/store/UiStore";

export function onBeforeUnload(event) {

  const uiStore = useInterfaceStore();
  if (uiStore.uuid == null || uiStore.saving) {
    event.returnValue = 'U heeft onopgeslagen wijzigingen. Weet u zeker dat u de pagina wilt verlaten?';
    return true;
  }

}