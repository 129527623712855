import { useLeadStore } from "@/store/LeadStore";
import { computed } from "vue";

export const measures = {
  cavity_wall: {
    key: 'cavity_wall',
    label: 'Spouwmuur<wbr>isolatie',
    value: 'Cavity Wall',
    icon: 'bi-bricks'
  },
  roof: {
    key: 'roof',
    label: 'Dakisolatie',
    value: 'Roof',
    icon: 'bi-chevron-double-up'
  },
  floor: {
    key: 'floor',
    label: 'Vloerisolatie',
    value: 'Floor',
    icon: 'bi-chevron-bar-down'
  },
  glazing: {
    key: 'glazing',
    label: 'H++ dubbelglas',
    value: 'Glazing',
    icon: 'bi-border-bottom'
  },
  heatpump: {
    key: 'heatpump',
    label: 'Warmtepomp',
    value: 'Heatpump',
    icon: 'bi-heatpump'
  },
  solar: {
    key: 'solar',
    label: 'Zonnepanelen',
    value: 'Solar',
    icon: 'bi-solar-roof'
  }
};

export const measuresArray = computed(() => {
  let mArray = [];
  for (const key in measures) {
    mArray.push(measures[key]);
  }
  return mArray;
});

export const getMeasureByKey = function(key) {
  if (key == null || key == '') { return null; }
  return (key in measures)
    ? measures[key]
    : null;
}

export const getMeasureByValue = function(value) {
  if (value == null || value == '') { return null; }
  for (const key in measures) {
    if (measures[key].value == value) {
      return measures[key];
    }
  }
  return null;
}

export const enabledMeasures = computed(() => {
  let lead = useLeadStore();
  let enabledMeasures = {};
  lead.interestsArray.forEach(measureValue => {
    const measureDefinition = getMeasureByValue(measureValue);
    if (measureDefinition) {
      enabledMeasures[measureDefinition.key] = measureDefinition;
    }
  });
  return enabledMeasures;
});